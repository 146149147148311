import React from 'react';
import classNames from 'classnames/bind';
import styles from './DetailsDrawer.module.css';

const cx = classNames.bind(styles);

const DetailsDrawer = ({active, children}) => {

    return (
      <div className={cx("base", {hidden: !active})}>
        {children}
      </div>
    );
};

export default DetailsDrawer;
