import React from 'react';
import styles from './App.css';
import './bootstrap.min.css';
import classNames from 'classnames/bind';
import HeroSection from "./Sections/HeroSection/HeroSection";
import ReadingSection from "./Sections/ReadingSection/ReadingSection";
import FooterSection from "./Sections/FooterSection/FooterSection";
import IntroSection from "./Sections/IntroSection/IntroSection";

const cx = classNames.bind(styles);

const App = () => {

  return(
    <div className={cx("App")}>
      <HeroSection />
      <IntroSection />
      <ReadingSection />
      <FooterSection />
    </div>
  );
}

export default App;
