import React from "react";
import classNames from "classnames/bind";
import styles from "./StepCard.module.css";

const cx = classNames.bind(styles);

const StepCard = ({ id, title, icon, children }) => {
  return (
    <div className={cx("base")}>
      <div className={cx("icon-box")}>{icon}</div>
      <div className={cx("title-box")}>
        <span>Stap {id}</span> <h2>{title}</h2>
      </div>
      <div className={cx("content-box")}>{children}</div>
    </div>
  );
};

export default StepCard;
