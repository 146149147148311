import React from "react";
import classNames from "classnames/bind";
import styles from "./Block.module.css";
import { Devices } from "@material-ui/icons";

const cx = classNames.bind(styles);

const monthNames = [
  "januari",
  "februari",
  "maart",
  "april",
  "mei",
  "juni",
  "juli",
  "augustus",
  "september",
  "october",
  "november",
  "december",
];

const Block = ({ title, subtitle, date, link, onClick }) => {
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  date.setDate(date.getDate() + 1);

  return (
    <div className={cx("base")} onClick={onClick}>
      <div className={cx("details")}>
        {/* <h1>{title}</h1> */}
        <p>
          <strong>{`${day} ${monthNames[month]}`}</strong>{" "}
          {title ? ` | ${title}` : null}
          {subtitle ? (
            ` | ${subtitle}`
          ) : (
            <small>{` | Bijbeltekst wordt nog bekendgemaakt`}</small>
          )}
        </p>
      </div>
      {link && (
        <a href={link} onClick={(e) => e.stopPropagation()}>
          Doe mee op Google meet <Devices style={{ marginLeft: "5px" }} />
        </a>
      )}
    </div>
  );
};

export default Block;
