import React from 'react';
import classNames from 'classnames/bind';
import styles from './IntroSection.module.css';

const cx = classNames.bind(styles);

const IntroSection = props => {

    return (
      <div className={cx("base")}>
        <h1>Een <strong>veilige plek</strong> om de moeilijke vragen te stellen
          en <strong>zelf te ontdekken</strong> wie Jezus is.</h1>
      </div>
        
    );
};

export default IntroSection;
