import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./ReadingSection.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Block from "../../Components/Block/Block";
import DetailsDrawer from "../../Components/DetailsDrawer/DetailsDrawer";
import StepCard from "../../Components/StepCard/StepCard";
import diagram from "./assets/diagram.png";
import example from "./assets/example.jpg";
import {
  ChatBubbleOutlined,
  MenuBook,
  Face,
  Create,
  Search,
  Help,
} from "@material-ui/icons";
import Modal from "../../Components/Modal/Modal";
import Markdown from "markdown-to-jsx";
import lessons from "./lessons";
import PublicGoogleSheetsParser from "public-google-sheets-parser";

const cx = classNames.bind(styles);

const dateStringToDate = (dateString) => {
  // Turn date from sheet into JS Date object
  const dateSubstring = dateString.substring(
    dateString.indexOf("(") + 1,
    dateString.lastIndexOf(")")
  );
  const year = parseInt(dateSubstring.split(",")[0]);
  const month = parseInt(dateSubstring.split(",")[1]);
  const day = parseInt(dateSubstring.split(",")[2]);

  const lessonDate = new Date(year, month, day, 20, 35);
  return lessonDate;
};

const ReadingSection = (props) => {
  const [selected, setSelected] = useState(0);
  const [modal, setModal] = useState(null);
  const [upcomingLessons, setUpcomingLessons] = useState([]);

  let lessonBlockCounter = 0;
  const spreadsheetId = "1Zch_qb0U7nYDTIHmWxtm1cWRGuNBQrLGHFTnlg-rPfw";

  const parser = new PublicGoogleSheetsParser(spreadsheetId);
  useEffect(() => {
    // Get lessons from Google Sheets parser
    parser.parse().then((items) => {
      const upcomingLessons = items.filter((item) => {
        const lessonDate = dateStringToDate(item.datum);
        const todaysDate = new Date();

        if (lessonDate >= todaysDate) {
          return true;
        }
      });

      // Set upcoming lessons from parser
      setUpcomingLessons(upcomingLessons);
      return upcomingLessons;
    });
  }, []);

  return (
    <div className={cx("base")}>
      {modal === 1 && (
        <Modal onClose={() => setModal(null)}>
          <img src={diagram} alt="flyer" />
        </Modal>
      )}
      {modal === 2 && (
        <Modal onClose={() => setModal(null)}>
          <img src={example} alt="flyer" />
        </Modal>
      )}

      {modal === 3 && (
        <Modal onClose={() => setModal(null)}>
          <div className={cx("why-write")}>
            <Help /> <h2>Waarom moet ik schrijven?</h2>
            <p>
              Als je iets herschrijft, verplicht je jezelf om het veel langzamer
              te lezen en om elk woord één voor één te bedenken (consider, in
              acht te nemen). Zo zullen bepaalde woorden en zinsdelen opvallen
              die je anders overheen zou lezen.
            </p>
            <p>
              Wij raden je aan om het schrijven te verspreiden over enkele dagen
              in je week. Focus je dan alleen op één stukje. Maak aantekeningen
              van alles op wat je opvalt aan dat stukje. Houd de vragen van stap
              4 in achterhoofd tijdens het lezen en schrijven.{" "}
            </p>
          </div>
        </Modal>
      )}
      {/* ------------ end of modals ----------- */}

      <header id="reading-section">
        <h1>
          <strong>Komende</strong> verhalen
        </h1>
        <h2>Persoonlijke voorbereiding</h2>
        <p>
          Niks hoeft. Alles mag. Hoe beter je je voorbereidt op het gesprek, hoe
          meer je er uiteindelijk uithaalt. Dit zijn de vijf stappen van
          persoonlijke voorbereiding die je door je week heen kunt doen om zo
          veel mogelijk uit elk verhaal te leren.
        </p>
      </header>

      <div className={cx("grid")}>
        {upcomingLessons.length > 0 &&
          upcomingLessons.map((lesson, idx) => {
            // const day = new Date(lesson.date).getDate();
            // const month = new Date(lesson.date).getMonth();

            const lessonDate = dateStringToDate(lesson.datum);
            // lessonDate.setDate(lessonDate.getDate() + 1);
            const todaysDate = new Date();

            if (lessonDate >= todaysDate && lessonBlockCounter < 3) {
              lessonBlockCounter++;
              return (
                <div key={idx} className={cx("lesson")}>
                  <Block
                    // id={lesson.id}
                    title={lesson.verhaal}
                    subtitle={lesson.bijbeltekst}
                    date={lessonDate}
                    link={lesson.googleMeet}
                    onClick={() =>
                      setSelected(selected === idx + 1 ? null : idx + 1)
                    }
                  />
                  {selected === idx + 1 && (
                    <DetailsDrawer active={selected === idx + 1}>
                      <StepCard
                        id={1}
                        icon={<ChatBubbleOutlined />}
                        title={"Bid"}
                      >
                        <p>
                          Vraag God om je te helpen om iets te leren over Jezus
                          wanneer je leest. <br />
                          <em style={{ fontSize: "10px" }}>
                            'Vraag en er zal je gegeven worden, zoek en je zult
                            vinden... Mattheüs 7:7'
                          </em>
                        </p>
                      </StepCard>

                      <StepCard id={2} icon={<MenuBook />} title={"Lees"}>
                        <p>
                          Lees <strong>{lesson.bijbeltekst}</strong>.
                        </p>
                      </StepCard>

                      <StepCard id={3} icon={<Create />} title={"Schrijf"}>
                        <div className={cx("write-step")}>
                          <p>
                            Teken twee verticale lijnen om{" "}
                            <button onClick={() => setModal(1)}>
                              3 kolommen op een vel papier
                            </button>{" "}
                            te maken.{" "}
                            <button onClick={() => setModal(3)}>Schrijf</button>{" "}
                            in de middelste kolom het hele verhaal uit,
                            woord-voor-woord. Markeer tijdens het schrijven alle
                            woorden of zinnen die je opvallen.{" "}
                            <button onClick={() => setModal(2)}>
                              Maak in de linker en rechter kolom aantekeningen
                            </button>{" "}
                            van bijzondere gedachten of vragen die bij je
                            opkomen tijdens het schrijven.
                          </p>
                        </div>
                      </StepCard>

                      <StepCard id={4} icon={<Face />} title={"Analyseer"}>
                        <p style={{ marginBottom: "0" }}>
                          Beantwoord tijdens het lezen en schrijven de volgende
                          vragen en schrijf uw antwoorden op:
                        </p>
                        <ul style={{ marginTop: "0" }}>
                          <li>
                            <strong>
                              Wat leer ik van dit verhaal over Jezus en God?
                            </strong>
                          </li>
                          <li>
                            <strong>
                              Wat heb ik geleerd van dit verhaal, dat ik deze
                              week in mijn leven zou willen toepassen?
                            </strong>
                          </li>
                        </ul>
                      </StepCard>

                      {lesson.extras && (
                        <StepCard id={5} icon={<Search />} title={"Verdiepen"}>
                          <Markdown>{lesson.extras}</Markdown>
                        </StepCard>
                      )}
                    </DetailsDrawer>
                  )}
                </div>
              );
            }
          })}
      </div>

      <footer>
        <h2>Het leven bestaat uit meer dan schermen</h2>
        <p>
          Zet even 20 minuten tot een halfuurtje vrij, het beste aan het begin
          of einde van je dag. Zet tijdens dit halfuurtje je telefoon op "Do not
          Disturb", "vliegmodus", of op "Zen modus". Zo kun je van deze tijd een
          lekker scherm-vrije moment maken. Dat zou ons allemaal goed doen in
          deze tijden. Doe dit op enkele (vaste) dagen in de week. Zo krijg je
          het meeste uit elk verhaal en uit elk groepsgesprek.
        </p>
      </footer>
    </div>
  );
};

export default ReadingSection;
