import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./HeroSection.module.css";
import Modal from "../../Components/Modal/Modal";
import flyerLg from "../../assets/flyer-lg.png";
import flyer from "../../assets/flyer.png";
import { ArrowDownward, CalendarToday, Devices } from "@material-ui/icons";

const cx = classNames.bind(styles);

const HeroSection = (props) => {
  const [showFlyerModal, setShowFlyerModal] = useState(false);
  return (
    <div className={cx("base")}>
      {showFlyerModal && (
        <Modal onClose={() => setShowFlyerModal(false)}>
          <img src={flyerLg} alt="flyer" />
        </Modal>
      )}

      <header className={cx("App-header")}>
        <img
          src={flyer}
          className={cx("flyer")}
          alt="flyer"
          onClick={() => setShowFlyerModal(true)}
        />

        <div className={cx("text")}>
          <span className={cx("label")}>
            Geen registratie | Iedereen is welkom!
          </span>

          <h1>Wie is Jezus?</h1>
          <h2>Wekelijkse gespreksgroep</h2>
          <h3>Elke woensdag</h3>
          {/*<p>Volgende: Woensdag 19 augustus</p>*/}
          {/*<p><em><strong>Wij gaan zo beginnen!</strong></em></p>*/}

          {/*<button onClick={() => window.scroll({*/}
          {/*  top: 700,*/}
          {/*  left: 100,*/}
          {/*  behavior: 'smooth'*/}
          {/*})}>Leesplan <CalendarToday style={{marginLeft: "5px"}}/></button>*/}

          <a href="#reading-section">
            Komende verhalen <ArrowDownward style={{ marginLeft: "5px" }} />
          </a>
        </div>
      </header>
    </div>
  );
};

export default HeroSection;
