import React from 'react';
import classNames from 'classnames/bind';
import styles from './FooterSection.module.css';
import logo from '../../assets/Logo_white.svg';

const cx = classNames.bind(styles);

const FooterSection = props => {

    return (
        <div className={cx("base")}>
          <h2>Door jong volwassenen georganiseerd, </h2>
          <h1>voor alle leeftijden bedoeld.</h1>
          <img className={cx("logo")} src={logo} title="Mail ons" alt="Impact Nederland logo" onClick={() => window.open("https://www.facebook.com/nederlandimpact/", "_blank")}/>
        </div>
    );
};

export default FooterSection;
