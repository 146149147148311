import React, {useRef} from 'react';
import classNames from 'classnames/bind';
import styles from './Modal.module.css';
import {Close} from "@material-ui/icons";
import useOnClickOutside from "../../Hooks/useOnClickOutside";

const cx = classNames.bind(styles);

const Modal = ({onClose, children, style}) => {
  const modal = useRef(null);
  useOnClickOutside(modal, onClose);

    return (
      <div className={cx("base")}>
        <div className={cx("modal")} ref={modal} style={style}>
          <Close className={cx("x-button")} onClick={onClose} />
          {children}</div>
      </div>
    );
};

export default Modal;
